import React from 'react';
// import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import { createRoot } from 'react-dom/client';

// import App from 'components/App';
import reducers from 'store/reducers';
import App from 'components/App';

/* 
  ! Enables redux devtools 
  Redux devtools, enabled for development environment
*/
let store = createStore(reducers, applyMiddleware(reduxThunk));

if(process.env.REACT_APP_NODE_ENV !== 'production'){
  const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)|| compose;
  store = createStore(reducers, composeEnhancers(applyMiddleware(reduxThunk)));
}

let root = createRoot(document.querySelector('#root'));
root.render(
  <Provider store={store} >
    {/* <App /> */}
    <App />
  </Provider>
)